export default async function initConfig() {
  let globalConfig = null
  await fetch('/config.json', { rejectUnauthorized: false })
    .then((response) => response.json())
    .then((data) => {
      globalConfig = data
    })
    .catch((error) => {
      console.error('Unable to load config.json')
      console.error('Error:', error)
    })
  return globalConfig
}
