import React, { useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { useSelector } from 'react-redux'
import { selectGlobalConfig } from '../App/appSlice'
import { checkPasswordComplexity } from '../../common/helpers/checkPasswordComplexity'
import Label from '../../common/Components/Label/Label'
import ViewErrorsList from '../../common/Components/ViewErrorsList/ViewErrorsList'
import LoadingButtonSpin from '../../common/Components/LoadingButtonSpin/LoadingButtonSpin'
import ResetPasswordSubmitViewModel from './ResetPasswordSubmitViewModel'
import leftArrowIcon from '../../common/imgs/aptimyz-left-arrow-icon.png'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import aptimyzInputErrorIcon from '../../common/imgs/aptimyz-input-error-icon.png'
import styles from './ResetPasswordSubmit.module.css'

export default function ResetPasswordSubmit() {
  const globalConfig = useSelector(selectGlobalConfig)
  const navigate = useNavigate()
  const { state } = useLocation()
  const [form] = Form.useForm()
  const {
    onFinish,
    onFinishFailed,
    onFormChange,
    isButtonLoading,
    viewErrorsMap
  } = ResetPasswordSubmitViewModel(globalConfig, navigate, form, state)

  useEffect(() => {
    !state && navigate('/not-found')
  }, [state])

  return (
    <div className={styles.resetPasswordSubmitContainer}>
      <Form.Provider onFormChange={onFormChange}>
        <Form
          form={form}
          name="reset-password-submit"
          requiredMark={false}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div
            data-testid="reset-password-submit-title-test-id"
            className={styles.resetPasswordSubmitPrimaryTitle}
          >
            Reset Password
          </div>
          <div
            data-testid="reset-password-submit-subtitle-test-id"
            className={styles.resetPasswordSubmitSecondaryTitle}
          >
            Enter the code that we sent to your email and proceed to write a new
            password for your account
          </div>
          <Form.Item
            label={<Label text="Code*" error={viewErrorsMap.code.length > 0} />}
            name="code"
            rules={[
              {
                required: true,
                message: 'The Code field is required'
              }
            ]}
          >
            <Input
              className={styles.resetPasswordSubmitInput}
              suffix={
                viewErrorsMap.code.length > 0 ? (
                  <img src={aptimyzInputErrorIcon} width={14} height={14} />
                ) : (
                  <span />
                )
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <Label
                text="New Password*"
                error={viewErrorsMap.password.length > 0}
              />
            }
            name="password"
            rules={[
              {
                required: true,
                message: 'The Password field is required'
              },
              {
                validator: async (_, password) => {
                  if (!checkPasswordComplexity(password)) {
                    return Promise.reject(
                      new Error(
                        'Password must contain at least 8 characters,\n' +
                          'at least 1 uppercase letter (A-Z),\n' +
                          'at least 1 lowercase letter (a-z),\n' +
                          'at least 1 special character (!@#)\n' +
                          'and at least 1 number (1-9)'
                      )
                    )
                  }
                }
              }
            ]}
          >
            <Input.Password
              className={styles.resetPasswordSubmitInput}
              iconRender={(visible) =>
                viewErrorsMap.password.length > 0 ? (
                  <img src={aptimyzInputErrorIcon} width={14} height={14} />
                ) : visible ? (
                  <EyeOutlined style={{ fontSize: '18px' }} />
                ) : (
                  <EyeInvisibleOutlined style={{ fontSize: '18px' }} />
                )
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <Label
                text="Confirm Password*"
                error={viewErrorsMap.passwordConfirm.length > 0}
              />
            }
            name="password-confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'The Confirm Password field is required'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('The new password that you entered do not match!')
                  )
                }
              })
            ]}
          >
            <Input.Password
              className={styles.resetPasswordSubmitInput}
              iconRender={(visible) =>
                viewErrorsMap.passwordConfirm.length > 0 ? (
                  <img src={aptimyzInputErrorIcon} width={14} height={14} />
                ) : visible ? (
                  <EyeOutlined style={{ fontSize: '18px' }} />
                ) : (
                  <EyeInvisibleOutlined style={{ fontSize: '18px' }} />
                )
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              role="button"
              type="primary"
              htmlType="submit"
              className={styles.resetPasswordSubmitButton}
            >
              <span className="auth-form-submit-button-content">
                {isButtonLoading && (
                  <LoadingButtonSpin
                    className={styles.resetPasswordSubmitButtonSpinner}
                  />
                )}
                Confirm
              </span>
            </Button>
          </Form.Item>
          <div
            data-testid="reset-password-submit-separator-line-test-id"
            className={styles.resetPasswordSubmitSeparatorLine}
          />
          <div
            data-testid="reset-password-submit-actions-text-test-id"
            className={styles.resetPasswordSubmitAction}
          >
            Don´t have an account? <Link to="/register">Register</Link>. Already
            have an account? <Link to="/login">Log In</Link>
          </div>
          <Button
            onClick={() =>
              navigate('/reset-password', {
                state: { userEmail: state?.userEmail }
              })
            }
            className={styles.resetPasswordSubmitGoBackButton}
          >
            <img src={leftArrowIcon} width={16} height={16} />
            Back
          </Button>
          <ViewErrorsList viewErrorsMap={viewErrorsMap} />
        </Form>
      </Form.Provider>
    </div>
  )
}
