import { useState, useEffect } from 'react'
import { signUp } from './helpers/helpers'
import { getRecaptchaToken } from '../../utils/reCaptcha'

export default function SignUpViewModel(globalConfig, navigate, form) {
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [viewErrorsMap, setViewErrorsMap] = useState({
    email: [],
    emailTaken: [],
    password: [],
    passwordConfirm: [],
    termsConditions: [],
    server: []
  })

  useEffect(() => {
    // Clear the navigation history
    if (window.history && window.history.pushState) {
      window.history.pushState(null, null, window.location.href)
    }
  }, [])

  /**
   * Define the actions after sending valid data.
   *
   * @param {object} values Object with the form data.
   */
  const onFinish = async (values) => {
    setIsButtonLoading(true)
    const securityToken =
      window.localStorage.getItem('e2e.securityToken') ??
      globalConfig?.reCaptcha?.token
    const reCaptchaToken = await getRecaptchaToken(
      globalConfig.reCaptcha.siteKey,
      'signup'
    )
    if (!reCaptchaToken || !securityToken) {
      setViewErrorsMap({
        email: [],
        password: [],
        passwordConfirm: [],
        termsConditions: [],
        server: ['The reCaptcha failed to load. Try again please.']
      })
    } else {
      const response = await signUp(values.email, values.password, {
        securityToken,
        captcha: reCaptchaToken
      })
      if (response.error) {
        if (
          response.error.code !== 'undefined' &&
          response.error.code === 'UserLambdaValidationException'
        ) {
          setViewErrorsMap({
            email: [],
            password: [],
            passwordConfirm: [],
            termsConditions: [],
            server: ['Invalid reCaptcha. Try again please.']
          })
        } else if (response.error.code === 'UsernameExistsException') {
          setViewErrorsMap({
            email: ['This Email is already in use.'],
            password: [],
            passwordConfirm: [],
            termsConditions: [],
            server: []
          })
        } else {
          setViewErrorsMap({
            email: [],
            password: [],
            passwordConfirm: [],
            termsConditions: [],
            server: ['An error has occurred, please try again.']
          })
        }
      } else {
        setViewErrorsMap({
          email: [],
          password: [],
          passwordConfirm: [],
          termsConditions: [],
          server: []
        })
        navigate('/email-verification', { state: { userEmail: values.email } })
      }
    }
    setIsButtonLoading(false)
  }

  /**
   * Define the actions after sending invalid data.
   *
   * @param {object} errorInfo Object with the form data and errors description.
   */
  const onFinishFailed = (errorInfo) => {}

  const onFormChange = (name, { values, forms }) => {
    if (name === 'sign-up') {
      const emailErrors = form.getFieldError('email')
      const passwordErrors = form.getFieldError('password')
      const passwordConfirmErrors = form.getFieldError('password-confirm')
      const termsConditionsErrors = form.getFieldError('legal-info-checkbox')
      setViewErrorsMap({
        email: emailErrors,
        password:
          passwordErrors.length > 0 ? [passwordErrors[0]] : passwordErrors,
        passwordConfirm: passwordConfirmErrors,
        termsConditions: termsConditionsErrors,
        server: []
      })
    }
  }

  const onValuesChange = (changedValues, allValues) =>
    changedValues.email &&
    setViewErrorsMap({
      email: [],
      password: viewErrorsMap.password,
      passwordConfirm: viewErrorsMap.passwordConfirm,
      termsConditions: viewErrorsMap.termsConditions,
      server: []
    })

  const onTCClick = () => window.open('https://www.aptimyz.com/terms-of-use')
  const onPPClick = () => window.open('https://www.aptimyz.com/privacy-policy')

  return {
    onFinish,
    onFinishFailed,
    onFormChange,
    onValuesChange,
    onTCClick,
    onPPClick,
    isButtonLoading,
    viewErrorsMap
  }
}
