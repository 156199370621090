import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { useSelector } from 'react-redux'
import { selectGlobalConfig } from '../App/appSlice'
import Label from '../../common/Components/Label/Label'
import ViewErrorsList from '../../common/Components/ViewErrorsList/ViewErrorsList'
import LoadingButtonSpin from '../../common/Components/LoadingButtonSpin/LoadingButtonSpin'
import EmailVerificationViewModel from './EmailVerificationViewModel'
import aptimyzInputErrorIcon from '../../common/imgs/aptimyz-input-error-icon.png'
import styles from './EmailVerification.module.css'

export default function EmailVerification() {
  const globalConfig = useSelector(selectGlobalConfig)
  const navigate = useNavigate()
  const { state } = useLocation()
  const [form] = Form.useForm()
  const {
    onFinish,
    onFinishFailed,
    onFormChange,
    isButtonLoading,
    viewErrorsMap
  } = EmailVerificationViewModel(globalConfig, navigate, form, state)

  useEffect(() => {
    !state && navigate('/not-found')
  }, [state])

  return (
    <div className={styles.emailVerificationContainer}>
      <Form.Provider onFormChange={onFormChange}>
        <Form
          form={form}
          name="email-verification"
          requiredMark={false}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div
            data-testid="email-verification-title-test-id"
            className={styles.emailVerificationPrimaryTitle}
          >
            Email Verification
          </div>
          <div
            data-testid="email-verification-subtitle-test-id"
            className={styles.emailVerificationSecondaryTitle}
          >
            We just sent to you a code to your Email to verify your Email
            Address. Please check your inbox.
          </div>
          <Form.Item
            label={<Label text="Code*" error={viewErrorsMap.code.length > 0} />}
            name="code"
            rules={[
              {
                required: true,
                message: 'The Code field is required'
              }
            ]}
          >
            <Input
              className={styles.emailVerificationInput}
              suffix={
                viewErrorsMap.code.length > 0 ? (
                  <img src={aptimyzInputErrorIcon} width={14} height={14} />
                ) : (
                  <span />
                )
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              role="button"
              type="primary"
              htmlType="submit"
              className={styles.emailVerificationButton}
            >
              <span className="auth-form-submit-button-content">
                {isButtonLoading && (
                  <LoadingButtonSpin
                    className={styles.emailVerificationButtonSpinner}
                  />
                )}
                Verify
              </span>
            </Button>
          </Form.Item>
          <Button
            onClick={() => navigate('/register')}
            className={styles.emailVerificationGoBackButton}
          >
            Back
          </Button>
          <ViewErrorsList viewErrorsMap={viewErrorsMap} />
        </Form>
      </Form.Provider>
    </div>
  )
}
