import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Form, Input, Checkbox } from 'antd'
import { useSelector } from 'react-redux'
import { selectGlobalConfig } from '../App/appSlice'
import { checkPasswordComplexity } from '../../common/helpers/checkPasswordComplexity'
import Label from '../../common/Components/Label/Label'
import ViewErrorsList from '../../common/Components/ViewErrorsList/ViewErrorsList'
import LoadingButtonSpin from '../../common/Components/LoadingButtonSpin/LoadingButtonSpin'
import SignUpViewModel from './SignUpViewModel'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import aptimyzInputErrorIcon from '../../common/imgs/aptimyz-input-error-icon.png'
import styles from './SignUp.module.css'

export default function SignUp() {
  const globalConfig = useSelector(selectGlobalConfig)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const {
    onFinish,
    onFinishFailed,
    onFormChange,
    onValuesChange,
    onTCClick,
    onPPClick,
    isButtonLoading,
    viewErrorsMap
  } = SignUpViewModel(globalConfig, navigate, form)

  return (
    <div className={styles.signUpContainer}>
      <Form.Provider onFormChange={onFormChange}>
        <Form
          form={form}
          name="sign-up"
          requiredMark={false}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          autoComplete="off"
        >
          <div
            data-testid="sign-up-title-test-id"
            className={styles.signUpPrimaryTitle}
          >
            Register
          </div>
          <div
            data-testid="sign-up-actions-text-test-id"
            className={styles.signUpAction}
          >
            Already have an account? <Link to="/login">Log In</Link>
          </div>
          <Form.Item
            label={
              <Label text="Email*" error={viewErrorsMap.email.length > 0} />
            }
            name="email"
            rules={[
              {
                required: true,
                message: 'The Email field is required'
              },
              {
                type: 'email',
                message: 'This Email is invalid'
              }
            ]}
          >
            <Input
              className={styles.signUpInput}
              status={viewErrorsMap.email.length > 0 && 'error'}
              suffix={
                viewErrorsMap.email.length > 0 ? (
                  <img src={aptimyzInputErrorIcon} width={14} height={14} />
                ) : (
                  <span />
                )
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <Label
                text="New Password*"
                error={viewErrorsMap.password.length > 0}
              />
            }
            name="password"
            rules={[
              {
                required: true,
                message: 'The Password field is required'
              },
              {
                validator: async (_, password) => {
                  if (!checkPasswordComplexity(password)) {
                    return Promise.reject(
                      new Error(
                        'Password must contain at least 8 characters,\n' +
                          'at least 1 uppercase letter (A-Z),\n' +
                          'at least 1 lowercase letter (a-z),\n' +
                          'at least 1 special character (!@#)\n' +
                          'and at least 1 number (1-9)'
                      )
                    )
                  }
                }
              }
            ]}
          >
            <Input.Password
              className={styles.signUpInput}
              iconRender={(visible) =>
                viewErrorsMap.password.length > 0 ? (
                  <img src={aptimyzInputErrorIcon} width={14} height={14} />
                ) : visible ? (
                  <EyeOutlined style={{ fontSize: '18px' }} />
                ) : (
                  <EyeInvisibleOutlined style={{ fontSize: '18px' }} />
                )
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <Label
                text="Confirm Password*"
                error={viewErrorsMap.passwordConfirm.length > 0}
              />
            }
            name="password-confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'The Confirm Password field is required'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('The new password that you entered do not match!')
                  )
                }
              })
            ]}
          >
            <Input.Password
              className={styles.signUpInput}
              iconRender={(visible) =>
                viewErrorsMap.passwordConfirm.length > 0 ? (
                  <img src={aptimyzInputErrorIcon} width={14} height={14} />
                ) : visible ? (
                  <EyeOutlined style={{ fontSize: '18px' }} />
                ) : (
                  <EyeInvisibleOutlined style={{ fontSize: '18px' }} />
                )
              }
            />
          </Form.Item>
          <Form.Item
            name="legal-info-checkbox"
            valuePropName="checked"
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error('You must agree to the Terms & Conditions')
                    )
                  }
                  return Promise.resolve()
                }
              })
            ]}
          >
            <div className={styles.signUpLegalInfo}>
              <Checkbox
                className={styles.signUpLegalInfoCheckbox}
                data-testid="sign-up-legal-info-checkbox-test-id"
              />
              I agree to the Aptimyz{' '}
              <Link onClick={onTCClick}>Terms & Conditions</Link> and{' '}
              <Link onClick={onPPClick}>Privacy Policy</Link>*
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              role="button"
              type="primary"
              htmlType="submit"
              className={styles.signUpButton}
            >
              <span className="auth-form-submit-button-content">
                {isButtonLoading && (
                  <LoadingButtonSpin className={styles.signUpButtonSpinner} />
                )}
                Create Account
              </span>
            </Button>
          </Form.Item>
          <ViewErrorsList viewErrorsMap={viewErrorsMap} />
        </Form>
      </Form.Provider>
    </div>
  )
}
