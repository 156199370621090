import React from 'react'
import PropTypes from 'prop-types'
import styles from './ViewErrorsList.module.css'

export default function ViewErrorsList(props) {
  const { viewErrorsMap } = props
  const errors = [
    ...(viewErrorsMap.code || []),
    ...(viewErrorsMap.email || []),
    ...(viewErrorsMap.password || []),
    ...(viewErrorsMap.passwordConfirm || []),
    ...(viewErrorsMap.termsConditions || []),
    ...(viewErrorsMap.server || [])
  ]
  return (
    <div className={styles.viewErrorsListContainer}>
      {errors.map((error, index) => {
        return (
          <div key={`error-${index}`} className={styles.viewErrorsListItem}>
            <div className={styles.viewErrorsListCircle} />
            <span className={styles.viewErrorsListError}>{error}</span>
          </div>
        )
      })}
    </div>
  )
}

ViewErrorsList.propTypes = {
  viewErrorsMap: PropTypes.shape({
    email: PropTypes.arrayOf(PropTypes.string),
    emailTaken: PropTypes.arrayOf(PropTypes.string),
    password: PropTypes.arrayOf(PropTypes.string),
    passwordConfirm: PropTypes.arrayOf(PropTypes.string),
    termsConditions: PropTypes.arrayOf(PropTypes.string),
    code: PropTypes.arrayOf(PropTypes.string),
    server: PropTypes.arrayOf(PropTypes.string)
  })
}
