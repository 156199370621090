import { Auth } from 'aws-amplify'

/**
 * Send confirmation code to user's email
 *
 * @param {string} email The user email, must be an string.
 * @param {object} clientMetadata The clientMetadata, must be an object.
 * @return {object} Object with the resulting data.
 */
export async function forgotPassword(email, clientMetadata) {
  const response = { user: null, error: null }
  try {
    response.user = await Auth.forgotPassword(email, clientMetadata)
  } catch (error) {
    response.error = error
  }
  return response
}
