import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button } from 'antd'
import styles from './EmailVerificationComplete.module.css'

export default function EmailVerificationComplete() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { userEmail } = state || {}

  useEffect(() => {
    !state && navigate('/not-found')
  }, [state])

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    navigate('/register')
  }

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent)
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [])

  return (
    <div className={styles.emailVerificationCompleteContainer}>
      <div className={styles.emailVerificationCompleteSection}>
        <div
          data-testid="email-verification-complete-title-test-id"
          className={styles.emailVerificationCompletePrimaryTitle}
        >
          Email Verified
        </div>
        <div
          data-testid="email-verification-complete-subtitle-test-id"
          className={styles.emailVerificationCompleteSecondaryTitle}
        >
          We have verified your Email Address! Please continue through the
          process
        </div>
        <div
          data-testid="email-verification-complete-verified-email-test-id"
          className={styles.emailVerificationCompleteEmailContainer}
        >
          {userEmail}
        </div>
        <Button
          role="button"
          type="primary"
          htmlType="submit"
          onClick={() => navigate('/login')}
          className={styles.emailVerificationCompleteButton}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
