import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, Outlet } from 'react-router-dom'
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'
import PropTypes from 'prop-types'
import LoadingView from '../LoadingView/LoadingView'
import AptimyzCoverImage from '../../imgs/aptimyz-cover-image.png'
import './AuthLayout.component.css'

export default function AuthLayout() {
  const location = useLocation()
  const { flagsReady } = useFlagsStatus()
  const showRegister = useFlag('auth_enable_register_form')
  const [isLoading, setIsLoading] = useState(true)
  const [showNotFoundPage, setShowNotFoundPage] = useState(false)

  useEffect(() => {
    if (location.pathname === '/register') {
      if (flagsReady && !showRegister) {
        setShowNotFoundPage(true)
      }
    }
    setIsLoading(false)
  }, [flagsReady, location])

  return !isLoading ? (
    !showNotFoundPage ? (
      <div data-testid="auth-layout-test-id" className="auth-flex-container">
        <img
          src={AptimyzCoverImage}
          alt="Cover Image"
          className="auth-layout-cover-image"
        />
        <Outlet />
      </div>
    ) : (
      <Navigate to="/not-found" />
    )
  ) : (
    <LoadingView />
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node
}
