import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { useSelector } from 'react-redux'
import { selectGlobalConfig } from '../App/appSlice'
import Label from '../../common/Components/Label/Label'
import ViewErrorsList from '../../common/Components/ViewErrorsList/ViewErrorsList'
import LoadingButtonSpin from '../../common/Components/LoadingButtonSpin/LoadingButtonSpin'
import SignInViewModel from './SignInViewModel'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import aptimyzInputErrorIcon from '../../common/imgs/aptimyz-input-error-icon.png'
import styles from './SignIn.module.css'

export default function SignIn() {
  const globalConfig = useSelector(selectGlobalConfig)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const {
    onFinish,
    onFinishFailed,
    onFormChange,
    isButtonLoading,
    viewErrorsMap
  } = SignInViewModel(globalConfig, navigate, form)

  return (
    <div className={styles.signInContainer}>
      <Form.Provider onFormChange={onFormChange}>
        <Form
          form={form}
          name="sign-in"
          requiredMark={false}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div
            data-testid="sign-in-title-test-id"
            className={styles.signInPrimaryTitle}
          >
            Log In
          </div>
          <Form.Item
            label={
              <Label text="Email*" error={viewErrorsMap.email.length > 0} />
            }
            name="email"
            rules={[
              {
                required: true,
                message: 'The Email field is required'
              },
              {
                type: 'email',
                message: 'This Email is invalid'
              }
            ]}
          >
            <Input
              className={styles.signInInput}
              suffix={
                viewErrorsMap.email.length > 0 ? (
                  <img src={aptimyzInputErrorIcon} width={14} height={14} />
                ) : (
                  <span />
                )
              }
            />
          </Form.Item>
          <Form.Item
            label={
              <Label
                text="Password*"
                error={viewErrorsMap.password.length > 0}
              />
            }
            name="password"
            rules={[
              {
                required: true,
                message: 'The Password field is required'
              }
            ]}
          >
            <Input.Password
              className={styles.signInInput}
              iconRender={(visible) =>
                viewErrorsMap.password.length > 0 ? (
                  <img src={aptimyzInputErrorIcon} width={14} height={14} />
                ) : visible ? (
                  <EyeOutlined style={{ fontSize: '18px' }} />
                ) : (
                  <EyeInvisibleOutlined style={{ fontSize: '18px' }} />
                )
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              role="button"
              type="primary"
              htmlType="submit"
              className={styles.signInButton}
            >
              <span className="auth-form-submit-button-content">
                {isButtonLoading && (
                  <LoadingButtonSpin className={styles.signInButtonSpinner} />
                )}
                Log In
              </span>
            </Button>
          </Form.Item>
          <div className={styles.signInLinkContainer}>
            <Link to="/reset-password">Forgot your Password?</Link>
          </div>
          <ViewErrorsList viewErrorsMap={viewErrorsMap} />
        </Form>
      </Form.Provider>
    </div>
  )
}
