import { useState } from 'react'
import { confirmSignUp } from './helpers/helpers'
import { getRecaptchaToken } from '../../utils/reCaptcha'

export default function EmailVerificationViewModel(
  globalConfig,
  navigate,
  form,
  state
) {
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [viewErrorsMap, setViewErrorsMap] = useState({
    code: [],
    server: []
  })
  const { userEmail } = state || {}

  /**
   * Define the actions after sending valid data.
   *
   * @param {object} values Object with the form data.
   */
  const onFinish = async (values) => {
    setIsButtonLoading(true)
    const securityToken =
      window.localStorage.getItem('e2e.securityToken') ??
      globalConfig?.reCaptcha?.token
    const reCaptchaToken = await getRecaptchaToken(
      globalConfig.reCaptcha.siteKey,
      'register'
    )
    if (!reCaptchaToken || !securityToken) {
      setViewErrorsMap({
        code: [],
        server: ['The reCaptcha failed to load. Try again please.']
      })
    } else {
      const response = await confirmSignUp(userEmail, values.code, {
        securityToken,
        captcha: reCaptchaToken
      })
      if (response.error) {
        if (
          response.error.code !== 'undefined' &&
          response.error.code === 'UserLambdaValidationException'
        ) {
          setViewErrorsMap({
            code: [],
            server: ['Invalid reCaptcha. Try again please.']
          })
        } else {
          setViewErrorsMap({
            code: [],
            server: ['An error has occurred, please try again.']
          })
        }
      } else {
        setViewErrorsMap({ code: [], server: [] })
        navigate('/email-verification-complete', {
          state: { userEmail },
          replace: true
        })
      }
    }
    setIsButtonLoading(false)
  }

  /**
   * Define the actions after sending invalid data.
   *
   * @param {object} errorInfo Object with the form data and errors description.
   */
  const onFinishFailed = (errorInfo) => {}

  const onFormChange = (name, { values, forms }) => {
    if (name === 'email-verification') {
      const codeErrors = form.getFieldError('code')
      setViewErrorsMap({
        code: codeErrors,
        server: []
      })
    }
  }

  return {
    onFinish,
    onFinishFailed,
    onFormChange,
    isButtonLoading,
    viewErrorsMap
  }
}
