import React from 'react'
import { useLocation } from 'react-router-dom'
import { Button } from 'antd'
import styles from './SelectRegion.module.css'
import SelectRegionViewModel from './SelectRegionViewModel'

export default function SelectRegion() {
  const { selectedRegion, handleSelectRegion, handleProceed } =
    SelectRegionViewModel()
  const { state } = useLocation()
  const { userName } = state || {}
  return (
    <div className={styles.selectRegionContainer}>
      <div className={styles.selectRegionContent}>
        <div className={styles.selectRegionTitle}>{`Welcome ${
          userName || ''
        }`}</div>
        <div className={styles.selectRegionSubtitle}>
          Please select the environment you would like to access:
        </div>
        <div className={styles.selectRegionButtonGroup}>
          <Button
            type={selectedRegion === 'EU' ? 'primary' : 'default'}
            onClick={() => handleSelectRegion('EU')}
            className={styles.selectRegionButton}
          >
            EU
          </Button>
          <Button
            type={selectedRegion === 'US' ? 'primary' : 'default'}
            onClick={() => handleSelectRegion('US')}
            className={styles.selectRegionButton}
          >
            US
          </Button>
        </div>
        <div className={styles.selectRegionProceedButtonContainer}>
          <Button
            type="primary"
            onClick={handleProceed}
            className={styles.selectRegionProceedButton}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  )
}
