import { Auth } from 'aws-amplify'

/**
 * Collect confirmation code, user email and new password
 *
 * @param {string} email The user email, must be an string.
 * @param {string} code The user confirmation code, must be an string.
 * @param {string} password The user password, must be an string.
 * @param {object} clientMetadata The clientMetadata, must be an object.
 * @return {object} Object with the resulting data.
 */
export async function forgotPasswordSubmit(
  email,
  code,
  newPassword,
  clientMetadata
) {
  const response = { user: null, error: null }
  try {
    response.user = await Auth.forgotPasswordSubmit(
      email,
      code,
      newPassword,
      clientMetadata
    )
  } catch (error) {
    response.error = error
  }
  return response
}
