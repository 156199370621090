import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import PropTypes from 'prop-types'

export default function LoadingButtonSpin(props) {
  const { className } = props

  const spinner = <LoadingOutlined className={className} spin />
  return <Spin indicator={spinner} />
}

LoadingButtonSpin.propTypes = {
  className: PropTypes.string
}
