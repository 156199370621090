const strongRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\])(?=.{8,})/

export function checkPasswordComplexity(password) {
  if (strongRegex.test(password)) {
    return true
  } else {
    return false
  }
}
