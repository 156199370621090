import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectGlobalConfig } from '../App/appSlice'

export default function SelectRegionViewModel() {
  const [selectedRegion, setSelectedRegion] = useState('EU')
  const globalConfig = useSelector(selectGlobalConfig)

  const handleSelectRegion = (region) => {
    setSelectedRegion(region)
  }
  const handleProceed = () => {
    const urlToRedirect = globalConfig.app.homePage[`${selectedRegion}`]
    window.location.assign(urlToRedirect)
  }
  return {
    selectedRegion,
    handleSelectRegion,
    handleProceed
  }
}
