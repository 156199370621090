import React from 'react'
import { Navigate } from 'react-router-dom'
import AuthLayout from '../../../common/Components/AuthLayout/AuthLayout'
import SignIn from '../../SignIn/SignIn'
import SignUp from '../../SignUp/SignUp'
import EmailVerification from '../../EmailVerification/EmailVerification'
import EmailVerificationComplete from '../../EmailVerificationComplete/EmailVerificationComplete'
import CompleteNewPassword from '../../CompleteNewPassword/CompleteNewPassword'
import ResetPassword from '../../ResetPassword/ResetPassword'
import ResetPasswordSubmit from '../../ResetPasswordSubmit/ResetPasswordSubmit'
import SelectRegion from '../../SelectRegion/SelectRegion'

/**
 * Create the app routes.
 *
 * @param {boolean} isUserLoggedIn The user status, must be a boolean.
 * @return {object} Object with the resulting data.
 */
export function routesConfig(userInfo) {
  return [
    {
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <SignIn />
        },
        {
          path: 'complete-new-password',
          element: <CompleteNewPassword />
        },
        {
          path: 'register',
          element: <SignUp />
        },
        {
          path: 'email-verification',
          element: <EmailVerification />
        },
        {
          path: 'email-verification-complete',
          element: <EmailVerificationComplete />
        }
      ]
    },
    {
      path: 'reset-password',
      element: <ResetPassword />
    },
    {
      path: 'reset-password-submit',
      element: <ResetPasswordSubmit />
    },
    {
      path: 'select-region',
      element: <SelectRegion />
    },
    {
      path: 'not-found',
      element: <div>You are lost</div>
    },
    {
      path: '/',
      element: !userInfo.isLoggedIn ? (
        <Navigate to="/login" />
      ) : userInfo.hasMultipleRegions ? (
        <Navigate to="/select-region" state={{ userName: userInfo.userName }} />
      ) : (
        <Navigate to="/not-found" />
      )
    }
  ]
}

/**
 * Returns an object with the Amplify configuration.
 *
 * @param {object} globalConfig The configuration data to use, must be an object.
 * @return {object} Object with the resulting data.
 */

export function authConfig(globalConfig) {
  return {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: globalConfig.cognito.region,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: globalConfig.cognito.userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: globalConfig.cognito.userPoolWebClientId,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: globalConfig.cognito.cookie.domain,
        // OPTIONAL - Cookie path
        path: globalConfig.cognito.cookie.path,
        // OPTIONAL - Cookie expiration in days
        expires: globalConfig.cognito.cookie.expires,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: globalConfig.cognito.cookie.sameSite,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true
      },

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_SRP_AUTH'
    }
  }
}
