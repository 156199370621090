export const customTheme = {
  token: {
    fontFamily: 'Poppins',
    fontSize: 12
  },
  components: {
    Form: {
      marginLG: 30,
      controlHeight: 22,
      paddingXS: 9,
      colorTextHeading: 'var(--gray-50)',
      colorError: 'var(--red-100)'
    },
    Input: {
      borderRadius: 10,
      colorBorder: 'var(--gray-30)',
      colorError: 'var(--red-100)',
      colorErrorBorderHover: 'var(--red-100)',
      colorPrimary: 'var(--green-10)',
      colorPrimaryActive: 'var(-green-60)',
      colorPrimaryHover: 'var(--green-110)',
      colorTextPlaceholder: 'var(--gray-50)',
      colorText: 'var(--gray-100)',
      controlOutline: '#24ad9a1a',
      controlHeight: 48,
      controlPaddingHorizontal: 14
    },
    Button: {
      borderRadius: 10,
      colorPrimary: 'var(--green-110)',
      colorPrimaryActive: 'var(--green-100)',
      colorPrimaryHover: 'var(--green-60)',
      colorBorder: 'var(--primary-green)',
      colorText: 'var(--primary-green)',
      controlHeight: 48
    },
    Checkbox: {
      colorBorder: 'var(--primary-green)',
      colorPrimary: 'var(--primary-green)',
      colorPrimaryBorder: 'var(--primary-green)',
      colorPrimaryHover: 'var(--primary-green)'
    }
  }
}
