import { Auth } from 'aws-amplify'

/**
 * Try to sign in a user.
 *
 * @param {string} email The user email, must be an string.
 * @param {string} password The user password, must be an string.
 * @param {object} clientMetadata The clientMetadata, must be an object.
 * @return {object} Object with the resulting data.
 */
export async function signIn(email, password, clientMetadata) {
  const response = { user: null, error: null }
  try {
    response.user = await Auth.signIn(email, password, clientMetadata)
  } catch (error) {
    response.error = error
  }
  return response
}
