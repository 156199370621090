import React from 'react'
import PropTypes from 'prop-types'
import './Label.component.css'

export default function Label({ text, error, className, styles }) {
  return (
    <span
      style={styles}
      className={error ? `label-error ${className}` : className}
    >
      {text}
    </span>
  )
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  className: PropTypes.string,
  styles: PropTypes.object
}
