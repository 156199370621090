import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { completeNewPassword } from './helpers/helpers'
import { getRecaptchaToken } from '../../utils/reCaptcha'
import { currentAuthenticatedUser } from '../App/helpers/userHandlers'

export default function CompleteNewPasswordViewModel(
  globalConfig,
  form,
  state
) {
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [viewErrorsMap, setViewErrorsMap] = useState({
    password: [],
    passwordConfirm: [],
    server: []
  })
  const navigate = useNavigate()
  const { email, oldPassword } = state || {}

  /**
   * Complete new password requirement.
   *
   * @param {object} values Object with the form data.
   */
  const onFinish = async (values) => {
    setIsButtonLoading(true)
    const securityToken =
      window.localStorage.getItem('e2e.securityToken') ??
      globalConfig?.reCaptcha?.token
    const reCaptchaToken = await getRecaptchaToken(
      globalConfig.reCaptcha.siteKey,
      'login'
    )
    if (!reCaptchaToken || !securityToken) {
      setViewErrorsMap({
        password: [],
        passwordConfirm: [],
        server: ['The reCaptcha failed to load. Try again please.']
      })
    } else {
      const response = await completeNewPassword(
        email,
        oldPassword,
        values.password,
        { securityToken, captcha: reCaptchaToken }
      )
      if (response.error) {
        if (
          response.error.code !== 'undefined' &&
          response.error.code === 'UserLambdaValidationException'
        ) {
          setViewErrorsMap({
            password: [],
            passwordConfirm: [],
            server: ['Invalid reCaptcha. Try again please.']
          })
        } else {
          setViewErrorsMap({
            password: [],
            passwordConfirm: [],
            server: ['An error has occurred, please try again.']
          })
        }
      } else {
        setViewErrorsMap({ password: [], passwordConfirm: [], server: [] })
        currentAuthenticatedUser().then((response) => {
          if (response.error) {
            setViewErrorsMap({
              password: [],
              passwordConfirm: [],
              server: ['An error has occurred, please try again.']
            })
          } else {
            const { attributes } = response.user
            if (
              !attributes['custom:environment'] ||
              attributes['custom:environment'] === 'EU,US' ||
              attributes['custom:environment'] === 'US,EU'
            ) {
              const userName = attributes.name || ''
              navigate('/select-region', {
                state: { userName }
              })
            } else {
              const urlToRedirect =
                globalConfig.app.homePage[`${attributes['custom:environment']}`]
              window.location.assign(urlToRedirect)
            }
          }
        })
      }
    }
    setIsButtonLoading(false)
  }

  /**
   * Define the actions after sending invalid data.
   *
   * @param {object} errorInfo Object with the form data and errors description.
   */
  const onFinishFailed = (errorInfo) => {}

  const onFormChange = (name, { values, forms }) => {
    if (name === 'complete-new-password') {
      const passwordErrors = form.getFieldError('password')
      const passwordConfirmErrors = form.getFieldError('password-confirm')
      setViewErrorsMap({
        password:
          passwordErrors.length > 0 ? [passwordErrors[0]] : passwordErrors,
        passwordConfirm: passwordConfirmErrors,
        server: []
      })
    }
  }

  return {
    onFinish,
    onFinishFailed,
    onFormChange,
    isButtonLoading,
    viewErrorsMap
  }
}
