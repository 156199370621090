import { Auth } from 'aws-amplify'

/**
 * Try to set up a new password for an user.
 *
 * @param {string} email The user email, must be an string.
 * @param {string} defaultPassword The user default password, must be an string.
 * @param {string} newPassword The user new password, must be an string.
 * @return {object} Object with the resulting data.
 */
export async function completeNewPassword(
  email,
  defaultPassword,
  newPassword,
  clientMetadata
) {
  const response = { user: null, error: null }
  await Auth.signIn(email, defaultPassword, clientMetadata)
    .then(async (user) => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword // the new password
        )
          .then((user) => {
            // at this time the user is logged in if no MFA required
            response.user = user
          })
          .catch((error) => {
            response.error = error
          })
      }
    })
    .catch((error) => {
      response.error = error
    })
  return response
}
