import { useState } from 'react'
import { forgotPassword } from './helpers/helpers'
import { getRecaptchaToken } from '../../utils/reCaptcha'

export default function ResetPasswordViewModel(globalConfig, navigate, form) {
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [viewErrorsMap, setViewErrorsMap] = useState({
    email: [],
    server: []
  })

  /**
   * Define the actions after sending valid data.
   *
   * @param {object} values Object with the form data.
   */
  const onFinish = async (values) => {
    setIsButtonLoading(true)
    const securityToken =
      window.localStorage.getItem('e2e.securityToken') ??
      globalConfig?.reCaptcha?.token
    const reCaptchaToken = await getRecaptchaToken(
      globalConfig.reCaptcha.siteKey,
      'forgotPassword'
    )
    if (!reCaptchaToken || !securityToken) {
      setViewErrorsMap({
        email: [],
        server: ['The reCaptcha failed to load. Try again please.']
      })
    } else {
      const response = await forgotPassword(values.email, {
        securityToken,
        reCaptchaToken
      })
      if (response.error) {
        if (
          response.error.code !== 'undefined' &&
          response.error.code === 'UserLambdaValidationException'
        ) {
          setViewErrorsMap({
            email: [],
            server: ['Invalid reCaptcha. Try again please.']
          })
        }
      } else {
        setViewErrorsMap({ email: [], server: [] })
        navigate('/reset-password-submit', {
          state: { userEmail: values.email }
        })
      }
    }
    setIsButtonLoading(false)
  }

  /**
   * Define the actions after sending invalid data.
   *
   * @param {object} errorInfo Object with the form data and errors description.
   */
  const onFinishFailed = (errorInfo) => {}

  const onFormChange = (name, { values, forms }) => {
    if (name === 'reset-password') {
      const emailErrors = form.getFieldError('email')
      setViewErrorsMap({
        email: emailErrors,
        server: []
      })
    }
  }

  const resendResetPasswordCode = () => {
    document.getElementById('reset-password-button').click()
  }

  return {
    onFinish,
    onFinishFailed,
    onFormChange,
    resendResetPasswordCode,
    isButtonLoading,
    viewErrorsMap
  }
}
