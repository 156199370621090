export const initRecaptcha = (siteKey) => {
  window.reCaptchaStatus = 'loading'
  const script = document.createElement('script')
  script.src = 'https://www.google.com/recaptcha/api.js?render=' + siteKey
  script.id = 'recaptcha'
  script.onload = () => {
    window.reCaptchaStatus = 'loaded'
    if (typeof grecaptcha !== 'undefined') {
      window.grecaptcha.ready(() => {
        window.reCaptchaStatus = 'ready'
      })
    }
  }
  script.onerror = () => {
    window.reCaptchaStatus = 'error'
  }
  document.head.appendChild(script)
}

export const getRecaptchaToken = async (siteKey, action) => {
  // Wait for recaptcha to load
  if (
    window.reCaptchaStatus === 'loading' ||
    window.reCaptchaStatus === 'loaded'
  ) {
    await new Promise((resolve) => setTimeout(resolve, 2000))
  }

  if (typeof grecaptcha === 'undefined') {
    console.log('greCaptcha not loaded')
    return false
  }

  try {
    // eslint-disable-next-line no-undef
    const response = await grecaptcha.execute(siteKey, { action })
    if (!response) {
      console.log('reCaptcha response is empty')
      return false
    } else {
      return response
    }
  } catch (error) {
    console.log('reCaptcha error: ' + error)
    return false
  }
}
