import { Auth } from 'aws-amplify'

/**
 * Try to register an user.
 *
 * @param {string} username The username, must be an string.
 * @param {string} password The user password, must be an string.
 * @param {object} clientMetadata The clientMetadata, must be an object.
 * @return {object} Object with the resulting data.
 */
export async function signUp(username, password, clientMetadata) {
  const response = { user: null, error: null }
  try {
    response.user = await Auth.signUp({ username, password, clientMetadata })
  } catch (error) {
    response.error = error
  }
  return response
}
