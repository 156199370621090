import { useState } from 'react'
import { signIn } from './helpers/helpers'
import { getRecaptchaToken } from '../../utils/reCaptcha'

export default function SignInViewModel(globalConfig, navigate, form) {
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [viewErrorsMap, setViewErrorsMap] = useState({
    email: [],
    password: [],
    server: []
  })

  /**
   * Define the actions after sending valid data.
   *
   * @param {object} values Object with the form data.
   */
  const onFinish = async (values) => {
    setIsButtonLoading(true)
    const securityToken =
      window.localStorage.getItem('e2e.securityToken') ??
      globalConfig?.reCaptcha?.token
    const reCaptchaToken = await getRecaptchaToken(
      globalConfig.reCaptcha.siteKey,
      'login'
    )
    if (!reCaptchaToken || !securityToken) {
      setViewErrorsMap({
        email: [],
        password: [],
        server: ['The reCaptcha failed to load. Try again please.']
      })
    } else {
      const response = await signIn(values.email, values.password, {
        securityToken,
        captcha: reCaptchaToken
      })
      if (response.error) {
        if (
          response.error.code !== 'undefined' &&
          response.error.code === 'UserLambdaValidationException'
        ) {
          setViewErrorsMap({
            email: [],
            password: [],
            server: ['Invalid reCaptcha. Try again please.']
          })
        } else if (response.error.code === 'UserNotConfirmedException') {
          setViewErrorsMap({
            email: [],
            password: [],
            server: ['User is not confirmed']
          })
        } else {
          setViewErrorsMap({
            email: [],
            password: [],
            server: ['Invalid email or password.']
          })
        }
      } else if (response.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setViewErrorsMap({ email: [], password: [], server: [] })
        navigate('/complete-new-password', {
          state: { email: values.email, oldPassword: values.password }
        })
      } else {
        setViewErrorsMap({ email: [], password: [], server: [] })
        const { attributes } = response.user
        if (
          !attributes['custom:environment'] ||
          attributes['custom:environment'] === 'EU,US' ||
          attributes['custom:environment'] === 'US,EU'
        ) {
          const userName = attributes.name || ''
          navigate('/select-region', {
            state: { userName }
          })
        } else {
          const urlToRedirect =
            globalConfig.app.homePage[`${attributes['custom:environment']}`]
          window.location.assign(urlToRedirect)
        }
      }
    }
    setIsButtonLoading(false)
  }

  /**
   * Define the actions after sending invalid data.
   *
   * @param {object} errorInfo Object with the form data and errors description.
   */
  const onFinishFailed = (errorInfo) => {}

  const onFormChange = (name, { values, forms }) => {
    if (name === 'sign-in') {
      const emailErrors = form.getFieldError('email')
      const passwordErrors = form.getFieldError('password')
      setViewErrorsMap({
        email: emailErrors,
        password: passwordErrors,
        server: []
      })
    }
  }

  return {
    onFinish,
    onFinishFailed,
    onFormChange,
    isButtonLoading,
    viewErrorsMap
  }
}
