import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createHashRouter, RouterProvider } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectGlobalConfigStatus,
  selectGlobalConfig,
  updateGlobalConfigStatus,
  updateGlobalConfig
} from './appSlice'
import { FlagProvider } from '@unleash/proxy-client-react'
import initConfig from '../../utils/configLoader'
import { initRecaptcha } from '../../utils/reCaptcha'
import { routesConfig, authConfig } from './helpers/helpers'
import { currentAuthenticatedUser } from './helpers/userHandlers'
import LoadingView from '../../common/Components/LoadingView/LoadingView'
import '../../common/styles/styles.css'

function App(props) {
  const { setupOnly } = props
  const dispatch = useDispatch()
  const globalConfig = useSelector(selectGlobalConfig)
  const globalConfigStatus = useSelector(selectGlobalConfigStatus)
  const [userInfo, setUserInfo] = useState(null)
  const [router, setRouter] = useState(null)

  useEffect(() => {
    initConfig().then((globalConfig) => {
      if (globalConfig) {
        const auth = authConfig(globalConfig)
        Amplify.configure(auth)
        initRecaptcha(globalConfig.reCaptcha.siteKey)
        dispatch(updateGlobalConfig(globalConfig))
        dispatch(updateGlobalConfigStatus('loaded'))
      } else {
        dispatch(updateGlobalConfigStatus('error'))
      }
    })
  }, [])

  useEffect(() => {
    if (globalConfigStatus === 'loaded') {
      currentAuthenticatedUser().then((response) => {
        if (response.error) {
          setUserInfo({ isLoggedIn: false, hasMultipleRegions: false })
        } else {
          const { attributes } = response.user
          if (
            !attributes['custom:environment'] ||
            attributes['custom:environment'] === 'EU,US' ||
            attributes['custom:environment'] === 'US,EU'
          ) {
            const userName = attributes.name || ''
            setUserInfo({
              isLoggedIn: true,
              hasMultipleRegions: true,
              userName
            })
          } else {
            const urlToRedirect =
              globalConfig.app.homePage[`${attributes['custom:environment']}`]
            window.location.assign(urlToRedirect)
          }
        }
      })
    }
  }, [globalConfigStatus])

  useEffect(() => {
    if (userInfo) {
      const router = createHashRouter(routesConfig(userInfo))
      setRouter(router)
    }
  }, [userInfo])

  return globalConfigStatus === 'loaded' && router !== null ? (
    !setupOnly ? (
      <FlagProvider config={globalConfig.unleash}>
        <RouterProvider router={router} />
      </FlagProvider>
    ) : null
  ) : globalConfigStatus === 'error' ? (
    <div>There is a problem with the app, please reaload de page</div>
  ) : (
    <LoadingView />
  )
}

App.propTypes = {
  setupOnly: PropTypes.bool
}

App.defaultProps = {
  setupOnly: false
}

export default App
