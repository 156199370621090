import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  globalConfigStatus: null,
  globalConfig: {}
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateGlobalConfigStatus: (state, action) => {
      state.globalConfigStatus = action.payload
    },
    updateGlobalConfig: (state, action) => {
      state.globalConfig = action.payload
    }
  }
})

export const { updateGlobalConfigStatus, updateGlobalConfig } = appSlice.actions
export const selectGlobalConfigStatus = (state) => state.app.globalConfigStatus
export const selectGlobalConfig = (state) => state.app.globalConfig
export default appSlice.reducer
