import React from 'react'
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { useSelector } from 'react-redux'
import { selectGlobalConfig } from '../App/appSlice'
import Label from '../../common/Components/Label/Label'
import ViewErrorsList from '../../common/Components/ViewErrorsList/ViewErrorsList'
import LoadingButtonSpin from '../../common/Components/LoadingButtonSpin/LoadingButtonSpin'
import LoadingView from '../../common/Components/LoadingView/LoadingView'
import ResetPasswordViewModel from './ResetPasswordViewModel'
import leftArrowIcon from '../../common/imgs/aptimyz-left-arrow-icon.png'
import aptimyzInputErrorIcon from '../../common/imgs/aptimyz-input-error-icon.png'
import styles from './ResetPassword.module.css'

export default function ResetPassword() {
  const globalConfig = useSelector(selectGlobalConfig)
  const { flagsReady } = useFlagsStatus()
  const showRegister = useFlag('auth_enable_register_form')
  const navigate = useNavigate()
  const { state } = useLocation()
  const [form] = Form.useForm()
  const {
    onFinish,
    onFinishFailed,
    onFormChange,
    resendResetPasswordCode,
    isButtonLoading,
    viewErrorsMap
  } = ResetPasswordViewModel(globalConfig, navigate, form)

  return flagsReady ? (
    <div className={styles.resetPasswordContainer}>
      <Form.Provider onFormChange={onFormChange}>
        <Form
          form={form}
          name="reset-password"
          initialValues={{ email: state?.userEmail }}
          requiredMark={false}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div
            data-testid="reset-password-title-test-id"
            className={styles.resetPasswordPrimaryTitle}
          >
            Reset Password
          </div>
          <div
            data-testid="reset-password-subtitle-test-id"
            className={styles.resetPasswordSecondaryTitle}
          >
            Don’t worry it happens. Please enter the email associated with your
            account to send a recovery code
          </div>
          <Form.Item
            label={
              <Label text="Email*" error={viewErrorsMap.email.length > 0} />
            }
            name="email"
            rules={[
              {
                required: true,
                message: 'The Email field is required'
              },
              {
                type: 'email',
                message: 'This Email is invalid'
              }
            ]}
          >
            <Input
              className={styles.resetPasswordInput}
              suffix={
                viewErrorsMap.email.length > 0 ? (
                  <img src={aptimyzInputErrorIcon} width={14} height={14} />
                ) : (
                  <span />
                )
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              role="button"
              type="primary"
              htmlType="submit"
              id="reset-password-button"
              className={styles.resetPasswordButton}
            >
              <span className="auth-form-submit-button-content">
                {isButtonLoading && (
                  <LoadingButtonSpin
                    className={styles.resetPasswordButtonSpinner}
                  />
                )}
                Send Email
              </span>
            </Button>
          </Form.Item>
          <div
            data-testid="reset-password-separator-line-test-id"
            className={styles.resetPasswordSeparatorLine}
          />
          <div
            data-testid="reset-password-info-text-test-id"
            className={styles.resetPasswordInfo}
          >
            <span>
              The email should arrive to your Mail Box in seconds. If not, check
              if your email is correct or click here
            </span>
            <span
              data-testid="reset-password-resend-link-test-id"
              className={styles.resetPasswordResend}
              onClick={resendResetPasswordCode}
            >
              to Resend Recovery Code
            </span>
          </div>
          {showRegister ? (
            <div
              data-testid="reset-password-actions-text-test-id"
              className={styles.resetPasswordAction}
            >
              Don´t have an account? <Link to="/register">Register</Link>.
              Already have an account? <Link to="/login">Log In</Link>
            </div>
          ) : (
            <div
              data-testid="reset-password-actions-text-test-id"
              className={styles.resetPasswordAction}
            >
              Already have an account? <Link to="/login">Log In</Link>
            </div>
          )}
          <Button
            onClick={() => navigate('/login')}
            className={styles.resetPasswordGoBackButton}
          >
            <img src={leftArrowIcon} width={16} height={16} />
            Back
          </Button>
          <ViewErrorsList viewErrorsMap={viewErrorsMap} />
        </Form>
      </Form.Provider>
    </div>
  ) : (
    <LoadingView />
  )
}
