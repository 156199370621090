import React from 'react'
import aptimyzLoadingIcon from '../../imgs/aptimyz-loading-icon.png'
import styles from './LoadingView.module.css'

export default function LoadingView() {
  return (
    <div className={styles.loadingViewContainer}>
      <img
        src={aptimyzLoadingIcon}
        alt="Loading Icon"
        className={styles.loadingViewRotatingImage}
      />
    </div>
  )
}
