import { Auth } from 'aws-amplify'

/**
 * Asynchronously retrieves the currently authenticated user.
 *
 * @return {Promise<{user: object | null, error: Error | null}>} An object containing the authenticated user (if successful) or an error (if unsuccessful).
 */
export async function currentAuthenticatedUser() {
  const response = { user: null, error: null }
  try {
    response.user = await Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
  } catch (error) {
    response.error = error
  }
  return response
}
